import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { EMPTY, Observable, ReplaySubject, catchError, from, tap } from 'rxjs';
const url = window.location.origin;
export const settings: UserManagerSettings & {
    protectedMap?: string[],
    excludeProtectedMap?: string[]
} = {
    authority: window.env.frontend.authentication.authority,
    client_id: window.env.frontend.authentication.clientId,
    client_secret: window.env.frontend.authentication.clientSecret,
    redirect_uri: url,
    post_logout_redirect_uri: window.location.href,
    response_type: "code",
    // scope: "openid email roles",
    response_mode: "fragment",
    loadUserInfo: false,
    filterProtocolClaims: true,
    revokeTokensOnSignout: true,
    revokeTokenTypes: ['access_token', 'refresh_token'],
    checkSessionIntervalInSeconds: 10,
    protectedMap: ['(http|https)://'],
    excludeProtectedMap: [window.env.geminiConfig.baseUrl],
    userStore: new WebStorageStateStore({ store: localStorage})
};
const oldXHROpen = window.XMLHttpRequest.prototype.open;

export class AuthenticationService {
    userManagement: UserManager;
    afterSignIn: (user: User, lang?: string) => void;
    private userLoaded$ = new ReplaySubject<User>(1);
    public userLoaded = this.userLoaded$.asObservable();
    constructor(settings: UserManagerSettings) {
        this.userManagement = new UserManager(settings);
        this.userManagement.events.addUserLoaded((e) => {
            this.userLoaded$.next(e);
            savePrincipal(e);
            localStorage.setItem('token',e?.access_token);
            if (!e.expired) {
                this.registerInterceptor(e);
            }
        })
    }
    public signIn({username, password, lang}) {
        const signIn = from(this.userManagement.signinResourceOwnerCredentials({username, password, skipUserInfo: true}));
        return signIn
        .pipe(tap(user => {
            if (this.afterSignIn) {
                this.afterSignIn(user, lang);
            }
        }));
    }
    public readUserAuthenticated(): Observable<User> {
        return from(this.userManagement.getUser()).pipe(catchError((err) => {
            return EMPTY;
        }), tap(user => this.registerInterceptor(user)));
    }
    public signOut() {
        settings.post_logout_redirect_uri = window.location.href;
        this.userManagement = new UserManager(settings);
        localStorage.clear();
         sessionStorage.clear();
        return from(this.userManagement.signoutRedirect());
    }
    private registerInterceptor(user:User) {
        const protectedMapUrl = settings.protectedMap || [window.location.origin];
        const excludeProtectedMapUrl = settings.excludeProtectedMap;
        XMLHttpRequest.prototype.open = function(method: string, url: string | URL) {
            const request = oldXHROpen.apply(this, arguments);
            
            let path: string;
            if (url instanceof URL) {
                path = url.href;
            } else {
                path = url;
            }
            
            if (!excludeProtectedMapUrl.find(p => path.search(p) > -1) 
                    && !!protectedMapUrl.find(p => path.search(p) > -1)) {
                let isKyc = checkUrlPathKyc(path);
                if (isKyc) {
                    this.setRequestHeader('Authorization', `${user?.token_type} ${window.env.backend.authorization.apiKey}`);
                } else {
                    if (localStorage.length > 0){
                        let token = localStorage.getItem('token');
                        this.setRequestHeader('Authorization', `${user?.token_type} ${token}`);
                    }
                }
            }
            return request;
        };
    }
}
function savePrincipal(user: User) {
    setCookie('crowd.token_key',user.refresh_token,user.expires_at);
    setCookie('keycloak.token',user.access_token, user.expires_at);
    setCookie('current_email',user.profile.email, user.expires_at);
    setCookie('domain_fe',window.location.origin, user.expires_at);
}

function setCookie(name: string, value: string, expiredAt: number) {
  const d = new Date(expiredAt*1000);
  let expires = `expires=${d.toUTCString()}`;
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function checkUrlPathKyc(pathUrl: string): Boolean {
    let isKyc: boolean = false;
    if (pathUrl.includes('?')){
        let domain = pathUrl.split('?')[0];
        if (domain.includes('/kyc/')) {
            isKyc = true;
        }
    } else {
        if (pathUrl.includes('/kyc/')) {
            isKyc = true;
        }
    }
    return isKyc;
}
