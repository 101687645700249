import { AxiosResponse } from 'axios';
import Axios from 'axios-observable';
import { catchError, EMPTY, forkJoin, map, mergeMap, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { DashboardInfo, DashboardResponse, Permission, ResponseModel, UserConfiguration, UserProfile, UserProfileResponse, UserProfileResponseModel } from '../models/user-profile.model';
import { AuthenticationService, settings } from './authentication.service';
import { User } from 'oidc-client-ts';
import { navigateToUrl } from 'single-spa';

const buildPageCommon = () => {
    return [
        {
            resource: {
                id: '9997',
                code: 'CHANGE_PASS',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['main','change-pass'],
                    label: 'CHANGE_PASS',
                    group: 'EU',
                    hidden: true,
                    order: 9997,
                }
            },
            policies: ['read']
        },
        {
            resource: {
                id: '9998',
                code: 'PAGE_NOT_PERMISSION',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['main','common', 'page-not-permission'],
                    label: 'PAGE_NOT_FOUND',
                    group: 'EU',
                    hidden: true,
                    order: 9998,
                }
            },
            policies: ['read']
        },
        {
            resource: {
                id: '9999',
                code: 'PAGE_NOT_FOUND',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['main','common', 'page-not-found'],
                    label: 'PAGE_NOT_FOUND',
                    group: 'EU',
                    hidden: true,
                    order: 9999,
                }
            },
            policies: ['read']
        }
    ]
}

const buildPermissionMenuOfExternalUser = (role: string) => {
    return [
        {
            resource: {
                id: '9997',
                code: 'CHANGE_PASS',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['main','change-pass'],
                    label: 'CHANGE_PASS',
                    group: 'EXU',
                    hidden: true,
                    order: 9997,
                }
            },
            policies: ['read']
        },
        {
            resource: {
                id: '9998',
                code: 'PAGE_NOT_PERMISSION',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['main','common', 'page-not-permission'],
                    label: 'PAGE_NOT_FOUND',
                    group: 'EXU',
                    hidden: true,
                    order: 9998,
                }
            },
            policies: ['read']
        },
        {
            resource: {
                id: '9999',
                code: 'PAGE_NOT_FOUND',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['main','common', 'page-not-found'],
                    label: 'PAGE_NOT_FOUND',
                    group: 'EXU',
                    hidden: true,
                    order: 9999,
                }
            },
            policies: ['read']
        },
        {
            resource: {
                id: '99999',
                code: 'HOME',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['main', 'home'],
                    label: 'HOMEPAGE.TITLE.LABEL',
                    group: 'EXU',
                    hidden: true,
                    order: 1,
                }
            },
            policies: ['read']
        },
        {
            resource: {
                id: '1', 
                code: 'DASHBOARD',
                type: 'menu',
                data: {
                    icon: 'home',
                    path: ['dashboard'],
                    label: 'HOMEPAGE.TITLE.LABEL',
                    group: 'EXU',
                    order: 1,
                    loadChildren: true,
                }
            },
            policies: ['read']
        },
        {
            resource: {
                id: '2',
                code: 'REQUEST',
                type: 'menu',
                data: {
                    icon: 'request',
                    path: ['main','request'],
                    label: 'LEFT_MENU.MY_REQUEST',
                    group: 'EXU',
                    order: 2,
                }
            },
            policies: ['read']
        }
    ]
}


const buildPermissionMenuOfEndUser = (role: string) => {
    return [
        {
        resource: {
            id: '99999',
            code: 'HOME',
            type: 'menu',
            data: {
                icon: 'home',
                path: ['main', 'home'],
                label: 'HOMEPAGE.TITLE.LABEL',
                group: 'EU',
                hidden: true,
                order: 1,
            }
        },
        policies: ['read']
    },
    {
        resource: {
            id: '1', 
            code: 'DASHBOARD',
            type: 'menu',
            data: {
                icon: 'home',
                path: ['dashboard'],
                label: 'HOMEPAGE.TITLE.LABEL',
                group: 'EU',
                order: 1,
                loadChildren: true,
            }
        },
        policies: ['read']
    },
    {
        resource: {
            id: '2',
            code: 'REQUEST',
            type: 'menu',
            data: {
                icon: 'request',
                path: ['main','request'],
                label: 'LEFT_MENU.MY_REQUEST',
                group: 'EU',
                order: 2,
            }
        },
        policies: ['read']
    },
    {
        resource: {
            id: '3',
            code: 'FAVOURITE',
            type: 'menu',
            data: {
                icon: 'favourite',
                path: ['main','favourite'],
                label: 'LEFT_MENU.FAVORITE',
                group: 'EU',
                order: 3,
            }
        },
        policies: ['read']
    },
    {
        resource: {
            id: '5',
            code: 'SERVICE_DELEGATION',
            type: 'menu',
            data: {
            	icon: 'delegation',
                path: ['main','delegate'],
                label: 'LEFT_MENU.DELEGATE',
                group: 'EU',
                order: 4,
            }
        },
        policies: ['read','create',...permission[role]['SERVICE_DELEGATION']]
        
    },
    {
        resource: {
            id: '6',
            code: 'CHANGE_PERFORMER',
            type: 'menu',
            data: {
                icon: 'change-owner',
                path: ['admin','change-performer'],
                label: 'LEFT_MENU.CHANGE_APPROVER',
                group: 'EU',
                order: 5,
            }
        },
        policies: ['read']
    },
    // TODO: Enale when finish CallCenter
    // {
    //     resource: {
    //         id: '7',
    //         code: 'CALL_CENTER',
    //         type: 'menu',
    //         data: {
    //             icon: 'call-center',
    //             path: ['main','call-center'],
    //             label: 'LEFT_MENU.CALL_CENTER',
    //             group: 'EU',
    //             order: 6,
    //         }
    //     },
    //     policies: ['read']
    // },
    // {
    //     resource: {
    //         id: '82',
    //         code: 'TASK_REPORT',
    //         type: 'menu',
    //         data: {
    //             icon: '',
    //             path: ['dashboard','task-report'],
    //             label: 'LEFT_MENU.TASK_REPORT',
    //             group: 'EU'
    //         },
    //         parent: '8'
    //     },
    //     policies: ['read']
    // },
    {
        resource: {
            id: '11',
            code: 'ADVANCE_SEARCH',
            type: 'menu',
            data: {
                icon: 'advance-search',
                path: ['main','advanced-search'],
                label: 'LEFT_MENU.ADVANCE_SEARCH',
                group: 'EU',
                order: 8,
            },
        },
        policies: ['read']
    },
    ]
}
const buildPermissionMenuOfAdminService = (role: string) => {
    return [{
            resource: {
                id: '24',
                code: 'PROCESS_CONFIGURATION',
                type: 'menu',
                data: {
                    icon: 'process-config',
                    path: ['admin','process-config'],
                    label: 'ADMIN_MENU.PROCESS_CONFIG',
                    group: 'AU',
                    order: 4,
                }
            },
            policies: permission[role]['PROCESS_CONFIGURATION']
        },
        {
            resource: {
                id: '17',
                code: 'STANDARD_CATEGORY_MANAGEMENT',
                type: 'menu',
                data: {
                    icon: 'category',
                    path: ['admin','category','standard'],
                    label: 'ADMIN_MENU.STANDARD_CATEGORY',
                    group: 'AU',
                    order: 7,
                }
            },
            policies: permission[role]['STANDARD_CATEGORY_MANAGEMENT']
        },
        {
            resource: {
                id: '13',
                code: 'ADVANCED_CATEGORY_MANAGEMENT',
                type: 'menu',
                data: {
                    icon: 'category',
                    path: ['admin','category','advance'],
                    label: 'ADMIN_MENU.ADVANCE_CATEGORY',
                    group: 'AU',
                    order: 8,
                }
            },
            policies: permission[role]['ADVANCED_CATEGORY_MANAGEMENT']
        },
        {
            resource: {
                id: '22',
                code: 'WORKFLOW_ROLE',
                type: 'menu',
                data: {
                    icon: 'workflow-role',
                    path: ['admin','workflow-role'],
                    label: 'ADMIN_MENU.WORKFLOW_ROLE',
                    group: 'AU',
                    order: 9,
                }
            },
            policies: permission[role]['WORKFLOW_ROLE']
        }
    ]
}

const buildPermissionMenuOfAdmin = (role: string) => {
    return [{
        resource: {
            id: '12',
            code: 'USER_MANAGEMENT',
            type: 'menu',
            data: {
                icon: 'account-management',
                path: ['admin','account'],
                label: 'ADMIN_MENU.USER_MANAGER',
                group: 'AU',
                order: 1,
            }
        },
        policies: permission[role]['USER_MANAGEMENT']
    },
    {
        resource: {
            id: '14',
            code: 'EMPLOYEE_MANAGEMENT',
            type: 'menu',
            data: {
                icon: 'employee',
                path: ['admin','employee'],
                label: 'ADMIN_MENU.USER_LIST',
                group: 'AU',
                order: 2,
            }
        },
        policies: permission[role]['EMPLOYEE_MANAGEMENT']
    },
    {
        resource: {
            id: '22',
            code: 'EXTERNAL_USER',
            type: 'menu',
            data: {
                icon: 'employee',
                path: ['admin','external-user'],
                label: 'ADMIN_MENU.EXTERNAL_USER',
                group: 'AU',
                order: 2,
            }
        },
        policies: permission[role]['EXTERNAL_USER']
    },
    {
        resource: {
            id: '15',
            code: 'DEPARTMENT_MANAGEMENT',
            type: 'menu',
            data: {
                icon: 'employee',
                path: ['admin','department'],
                label: 'ADMIN_MENU.DEPARTMENT',
                group: 'AU',
                order: 3,
            }
        },
        policies: permission[role]['DEPARTMENT_MANAGEMENT']
    },
    {
        resource: {
            id: '18',
            code: 'USER_ROLE',
            type: 'menu',
            data: {
                icon: 'user-role',
                path: ['admin','user-role'],
                label: 'ADMIN_MENU.USER_ROLE',
                group: 'AU',
                order: 5,
            }
        },
        policies: permission[role]['USER_ROLE']
    },
    {
        resource: {
            id: '18',
            code: 'USER_ROLE',
            type: 'menu',
            data: {
                icon: 'user-role',
                path: ['admin','role'],
                label: 'ADMIN_MENU.ROLE',
                group: 'AU',
                order: 5,
            }
        },
        policies: permission[role]['USER_ROLE']
    },
    {
        resource: {
            id: '16',
            code: 'SYSTEM_CATEGORY_MANAGEMENT',
            type: 'menu',
            data: {
                icon: 'system-category',
                path: ['admin','category','system'],
                label: 'ADMIN_MENU.SYSTEM_CATEGORY',
                group: 'AU',
                order: 6,
            }
        },
        policies: permission[role]['SYSTEM_CATEGORY_MANAGEMENT']
    },
    {
        resource: {
            id: '20',
            code: 'SLA_WORKING_DAY',
            type: 'menu',
            data: {
                icon: 'working-day',
                path: ['admin','sla-working-day'],
                label: 'ADMIN_MENU.SLA_WORKING_DAY',
                group: 'AU',
                order: 10,
            }
        },
        policies: permission[role]['SLA_WORKING_DAY']
    },
    {
        resource: {
            id: '21',
            code: 'SLA_WORKING_TIME',
            type: 'menu',
            data: {
                icon: 'working-time',
                path: ['admin','sla-working-time'],
                label: 'ADMIN_MENU.SLA_WORKING_HOUR',
                group: 'AU',
                order: 11,
            }
        },
        policies: permission[role]['SLA_WORKING_TIME']
    },
    {
        resource: {
            id: '19',
            code: 'SLA_HOLIDAY',
            type: 'menu',
            data: {
                icon: 'holiday',
                path: ['admin','sla-holiday'],
                label: 'ADMIN_MENU.SLA_HOLIDAY',
                group: 'AU',
                order: 12,
            }
        },
        policies: permission[role]['SLA_HOLIDAY']
    },
    {
        resource: {
            id: '8',
            code: 'DASHBOARD',
            type: 'menu',
            data: {
                icon: 'report',
                path: ['dashboard'],
                label: 'LEFT_MENU.DASHBOARD',
                group: 'EU',
                order: 7,
            }
        },
        policies: ['read']
    },
    {
        resource: {
            id: '81',
            code: 'REQUEST_REPORT',
            type: 'menu',
            data: {
                icon: '',
                path: ['dashboard','request-report'],
                label: 'LEFT_MENU.REQUEST_REPORT',
                group: 'EU',
                order: 12,
            },
            parent: '8'
        },
        policies: permission[role]['REQUEST_REPORT']
    }
    ,{
        resource: {
            id: '82',
            code: 'TASK_REPORT',
            type: 'menu',
            data: {
                icon: '',
                path: ['dashboard','task-report'],
                label: 'LEFT_MENU.TASK_REPORT',
                group: 'EU',
                order: 13,
            },
            parent: '8'
        },
        policies: permission[role]['TASK_REPORT']
    }
    // {
    //     resource: {
    //         id: '20',
    //         code: 'JOB_MANAGEMENT',
    //         type: 'menu',
    //         data: {
    //             icon: 'job-management',
    //             path: ['admin','job-management'],
    //             label: 'ADMIN_MENU.JOB_MANAGEMENT',
    //             group: 'AU',
    //             order: 13,
    //         }
    //     },
    //     policies: permission[role]['JOB_MANAGEMENT']
    // }
]
}
const permission = {
    admin: {
        PROCESS_CONFIGURATION: ['read', 'update', 'delete', 'import', 'export', 'create'],
        SYSTEM_CATEGORY_MANAGEMENT: ['read', 'update', 'delete', 'import', 'export', 'create'],
        STANDARD_CATEGORY_MANAGEMENT: ['read', 'update', 'delete', 'import', 'export', 'create'],
        ADVANCED_CATEGORY_MANAGEMENT: ['read', 'update', 'delete', 'import', 'export', 'create'],
        WORKFLOW_ROLE: ['read', 'update', 'delete', 'import', 'export', 'create'],
        USER_MANAGEMENT: ['read', 'update', 'delete', 'import', 'export', 'create'],
        EMPLOYEE_MANAGEMENT: ['read', 'update', 'delete', 'import', 'export', 'create'],
        DEPARTMENT_MANAGEMENT: ['read', 'update', 'delete', 'import', 'export', 'create'],
        EXTERNAL_USER: ['read', 'update', 'delete', 'import', 'export', 'create'],
        USER_ROLE: ['read', 'update', 'delete', 'import', 'export', 'create'],
        SLA_WORKING_DAY: ['read', 'update', 'delete', 'import', 'export', 'create'],
        SLA_WORKING_TIME: ['read', 'update', 'delete', 'import', 'export', 'create'],
        SLA_HOLIDAY: ['read', 'update', 'delete', 'import', 'export', 'create'],
        JOB_MANAGEMENT: ['read', 'update', 'delete', 'import', 'export', 'create'],
        SERVICE_DELEGATION: ['assign'],
        REQUEST_REPORT:['read'],
        TASK_REPORT:['read']
    },
    admin_service: {
        PROCESS_CONFIGURATION: ['read', 'update'],
        SYSTEM_CATEGORY_MANAGEMENT: [],
        STANDARD_CATEGORY_MANAGEMENT: ['read'],
        ADVANCED_CATEGORY_MANAGEMENT: ['read'],
        WORKFLOW_ROLE: ['read'],
        USER_MANAGEMENT: [],
        EMPLOYEE_MANAGEMENT: [],
        DEPARTMENT_MANAGEMENT: [],
        EXTERNAL_USER: [],
        USER_ROLE: [],
        SLA_WORKING_DAY: [],
        SLA_WORKING_TIME: [],
        SLA_HOLIDAY: [],
        JOB_MANAGEMENT: [],
        SERVICE_DELEGATION: []
    },
    user:{
        SERVICE_DELEGATION: []
    },

}
Axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.withCredentials = true;
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
 Axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
export class UserProfileService {
    public authenticationService: AuthenticationService;
    private userProfile$ = new ReplaySubject<UserProfile | null>(1);
    public userProfile = this.userProfile$.asObservable();
    private useDashboard: boolean = true;
    

    public isAuthenticated = false;
    private authenticated$ = new ReplaySubject<boolean>(1);
    public authenticated = this.authenticated$.asObservable();

    private userConfiguration$ = new ReplaySubject<UserConfiguration>(1);
    public userConfiguration = this.userConfiguration$.asObservable();

    public userLoaded: Observable<User>;
    public listDashboards: DashboardInfo[];
    public current_role: string;
    //Status code license expired date
    STATUS_CODE_LICENSE_EXPIRED:number = 402;
    public cache = {
        
    }
    constructor() {
        this.authenticationService = new AuthenticationService(settings);
        this.userLoaded = this.authenticationService.userLoaded;
        this.authenticationService.afterSignIn = (user, lang) => {
            this.loadUserProfile(lang).pipe(take(1)).subscribe();
        };
    }


    private setUserProfile(profile: UserProfile): void {
        const authenticated = !!profile;
        this.userProfile$.next(profile);
        this.authenticated$.next(authenticated);
        this.isAuthenticated = (authenticated);
    }

    private  readListDashboard(): Observable<DashboardInfo[]>{
        const uri:string = `${window.env.backend.dashboard.baseUrl}dashboard?size=1000&page=0`;
        return Axios.get(uri).pipe(
            map((response: AxiosResponse<ResponseModel>)=>{
                let dashboards: DashboardInfo[] = [];
                if(!!response &&  !!response.data && !!response.data.responseData.dashboards){
                    dashboards =  response.data.responseData.dashboards;
                }
                this.listDashboards = dashboards;
                return dashboards;
            }),
            catchError((errors,caught)=>{
                this.useDashboard = false;
                return of([]);
            })
        ); 
    }

    filterDisplayBoardList(userProfile: UserProfile, dashboards: DashboardInfo[]): DashboardInfo[]{
        let filteredDashboard: DashboardInfo[] =[];
        if(!!userProfile && !!dashboards){
            const currentHomeDashboard: DashboardInfo = this.getCurrentHomeDashboard(userProfile, dashboards);
            let homeDashboardId: string = null;
            let maxRecordDisplay: number = 5;
            if(!!currentHomeDashboard){
                maxRecordDisplay-=1;
                homeDashboardId = currentHomeDashboard.id;
                filteredDashboard.push(currentHomeDashboard);
            }

            const filterDashboard = dashboards.filter(dashboard=>dashboard.id !== homeDashboardId).slice(0,maxRecordDisplay);
            filteredDashboard = [...filteredDashboard,...filterDashboard];
        }
        return filteredDashboard;
    }

    getCurrentHomeDashboard(userProfile: UserProfile, dashboards: DashboardInfo[]): DashboardInfo| undefined{
        let dashboard: DashboardInfo| undefined = undefined;
        if(!!userProfile && !!dashboards && !!userProfile.profile){
            dashboard  = dashboards.find(dashboard=>{
                const homepageUrl = `dashboard/view/${dashboard.id}`;
                return homepageUrl === userProfile.profile.homepage;
            })
            dashboard  = dashboards.find(item=>{
                return 'default' === item.id;
            })
        }
        return dashboard;
    }

    mapDashboardListToPermission(dashboards: DashboardInfo[]): Permission[]{
        let dashboardPermission: any[]= [];
        if (dashboards.length > 0) {
            dashboardPermission = dashboards.map(
                dashboard => {
                    return {
                        resource: {
                            id: '',
                            code: dashboard.name,
                            type: 'menu',
                            data: {
                                icon: '',
                                path: ['dashboard','view',`${dashboard.id}`],
                                label: dashboard.name,
                                group: 'EU',
                                type: '',
                            },
                            parent: '1'
                        },
                        policies: ['read']
                    };
                }
            )
        }
        dashboardPermission.push({
            resource: {
                id: '',
                code: 'DASHBOARD_MNG',
                type: 'menu',
                data: {
                    icon: '',
                    path: ['dashboard'],
                    label: 'HOMEPAGE.TABLE.LABEL',
                    group: 'EU',
                    type: 'link',
                },
                parent: '1'
            },
            policies: ['read']
        })
        return dashboardPermission as unknown as Permission[];
    }
  
    public readUserInfo(lang?: string):Observable<UserProfile>{
        const userInfoEndpoint: string = `${window.env.backend.authorization.userInfo}/user-profile`;

        return Axios.get(userInfoEndpoint).pipe(
            map(
                //(response: AxiosResponse<UserProfileResponseModel>)=> {
                (response: any)=> {
                    
                    if(response.data.statusCode == this.STATUS_CODE_LICENSE_EXPIRED) {

                      if (response.data.responseData.admin_redirect == 'true') {
                        this.current_role = "admin";
                        navigateToUrl("/license/-");                       
                      } else {
                        this.current_role = "not_admin";
                        throw(response);                        
                      }
        
                    } 
                    const userProfile = response.data.responseData;
                    let permissions = buildPermissionMenuOfEndUser('user');
                    if (userProfile.role === 'admin_service') { 
                        permissions = buildPermissionMenuOfEndUser('admin_service');
                        permissions.push(...buildPermissionMenuOfAdminService('admin_service'));
                    } else if (userProfile.role === 'admin') {
                        permissions = buildPermissionMenuOfEndUser('admin');
                        permissions.push(...buildPermissionMenuOfAdminService('admin'));
                        permissions.push(...buildPermissionMenuOfAdmin('admin'));
                    }
                    //Set role to check global role
                    this.current_role = userProfile.role;
                    permissions.push(...buildPageCommon());
                    if(!!userProfile.euserId){
                        permissions = buildPermissionMenuOfExternalUser('external-user');
                    }
                    const profile: UserProfile = {
                        profile: {
                            name: response.data?.responseData?.fullName,
                            email : userProfile.email,
                            homepage: userProfile.homepage,
                        },
                        permissions: permissions
                    } as unknown as UserProfile;
                    let languageCode: string = lang || userProfile.languageKey || navigator.language;
                    const config = this.buildUserConfig(languageCode);
                    config.homepage = userProfile.homepage || '';
                    this.setUserConfiguration(config);
                               
                    return profile;
                }
            )
        );
    } 

    public loadUserProfile(lang?: string) : Observable<UserProfile> {
        // Call api read user-profile
        const readUserInfoObs:Observable<UserProfile> = this.readUserInfo(lang); 
        const readDashboardObs: Observable<DashboardInfo[]> = this.readListDashboard();
        return this.authenticationService.readUserAuthenticated().pipe(mergeMap(res => {
            if (!res || res.expired) {
                return EMPTY;
            }
            return forkJoin({
                userProfile: readUserInfoObs,
                dashboards: readDashboardObs
            }).pipe(
                map(data => {
                    const profile = data.userProfile;
                    const dashboards = data.dashboards;
                    const filteredDashboard: DashboardInfo[] = this.filterDisplayBoardList(profile,dashboards);
                    const dashboardChildPermission : Permission[] = this.mapDashboardListToPermission(filteredDashboard);
                    profile.permissions =  profile.permissions.concat(dashboardChildPermission);
                    if (!this.useDashboard){
                        profile.permissions = this.removeDashboardPermission(profile.permissions);
                        profile.profile.homepage = '/main';
                    } else{
                        let homeUrl = profile.profile.homepage ? profile.profile.homepage : 'dashboard/view/default';
                        if(!!profile.profile.homepage){
                            const viewId: string = this.getDashboardViewId(profile.profile.homepage);
                            if(!this.dashboardViewExists(viewId,dashboards)){
                                homeUrl = 'dashboard/view/default';
                            }
                        }
                        profile.profile.homepage = homeUrl;
                    }
                    return profile;
                }),
                catchError((err, caught) => {
                    if (err?.response?.status == 401) {
                        return this.authenticationService.signOut().pipe(switchMap(()=>EMPTY));
                    }
                    this.setUserProfile(null);
                    return EMPTY;
                }),
                tap(p => {
                    this.setUserProfile(p);
                    this.userConfiguration.pipe(take(1)).subscribe(
                        (userConfig: UserConfiguration)=>{    
                        this.setUserConfiguration({
                            ...userConfig,
                            homepage: p.profile.homepage
                        })
                        }
                    )
                })
            );
        }));

    }

    dashboardViewExists(viewId: string, dashboards: DashboardInfo[]) {
        if(!!viewId && !!dashboards){
            return !!dashboards.find(dashboard=>dashboard.id === viewId);
        }
        return false;
    }
    getDashboardViewId(homepage: string): string {
        let viewId : string = "default";
        if(!!homepage){
            const tokenRouting = homepage.split("/");
            viewId = tokenRouting[tokenRouting.length-1];
        }
        return viewId;
    }
    
    removeDashboardPermission(permissions: Permission[]): Permission[] {
        let convertedPermission: Permission[] = permissions;
        if(!!convertedPermission ){
            convertedPermission = convertedPermission.filter(permission=>'DASHBOARD' !== permission.resource.code);
        }
        return convertedPermission
    }

    public setUserConfiguration(userConfiguration: UserConfiguration) {
        this.userConfiguration$.next(userConfiguration);
    }
    public storeProfile(token: string) {
        // localStorage.setItem(UserProfileService.TOKEN_KEY, token);
        if (token) {
            setTimeout(() => {
                return this.loadUserProfile();
            }, 0.5)
        }
        this.setUserProfile(null);
        return EMPTY;
    }
    private buildUserConfig(language: string): UserConfiguration {
        const languageSetting = {
            'vi-VN': {dateFormat: 'DD/MM/YYYY', timeFormat: 'HH:mm:ss'},
            'en-US': { dateFormat: 'YYYY/MM/DD', timeFormat: 'HH:mm:ss'},
            'ja-JP': { dateFormat: 'YYYY年MM月DD日', timeFormat: 'HH:mm:ss'}
        }
        return {
            language: language,
            ...languageSetting[language]
        }
    }

    public signOut() {
        return this.authenticationService.signOut();
    }
}